import request from '@/utils/request'

export function store(data) {
  return request({
    url: 'serial/store',
    method: 'post',
    data
  });
}

export function update(id, data) {
  return request({
    url: 'serial/update/' + id,
    method: 'post',
    data
  });
}

export function getSerialById(id) {
  return request({
    url: 'serial/getSerialById/' + id,
    method: 'get'
  });
}

export function getSerials() {
  return request({
    url: 'serial/getSerials',
    method: 'get'
  });
}

export function exportPlayer(serial_id) {
  return request({
    url: 'serial/exportPlayers/' + serial_id,
    method: 'get'
  });
}

export function deleteTables(serial_id) {
  return request({
    url: 'serial/deleteTables/' + serial_id,
    method: 'delete'
  });
}

export function deleteSerial(serial_id) {
  return request({
    url: 'serial/deleteSerial/' + serial_id,
    method: 'delete'
  });
}

export function setMaintenance(maintenance) {
  return request({
    url: 'serial/setMaintenance',
    method: 'post',
    data: {
      'maintenance':maintenance
    }
  });
}

export function resetSerial(serial_id) {
  return request({
    url: 'serial/resetSerial/' + serial_id,
    method: 'get'
  });
}

export function deletePlayerVerify(serial_id) {
  return request({
    url: 'serial/deletePlayerVerify/' + serial_id,
    method: 'delete'
  });
}

export function updateTableConfig(id, data) {
  return request({
    url: 'serial/updateTableConfig/' + id,
    method: 'post',
    data
  });
}

export function createPlayers(id, data) {
  return request({
    url: 'serial/createPlayers/' + id,
    method: 'post'
  });
}